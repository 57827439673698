import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SurfingIcon from "@mui/icons-material/Surfing";
import AddIcon from "@mui/icons-material/Add";
import ListItemText from "@mui/material/ListItemText";

import Context from "../../context";
const CustomizedListItemButton = styled(ListItemButton)`
    color: ${(props) => (props.isActive ? "green" : "")};

    :hover {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
    :active {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
`;

const CustomizedAddIcon = styled(AddIcon)`
    position: absolute;
    left: 0.5em;
    color: ${(props) => (props.isActive ? "green" : "")};

    :hover {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
    :active {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
`;

const CustomizedSurfIcon = styled(SurfingIcon)`
    color: ${(props) => (props.isActive ? "green" : "")};

    :hover {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
    :active {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
`;

export default function AddSurfSpotButton() {
    const { setAddSurfSpot, addSurfSpot } = useContext(Context);

    const isActive = addSurfSpot;
    return (
        <CustomizedListItemButton
            button
            isActive={isActive}
            // divider
            // className={styles.isSetSurfReport}
            onClick={() => setAddSurfSpot((isActive) => !isActive)}
        >
            <ListItemIcon>
                <div style={{ position: "relative" }}>
                    <CustomizedSurfIcon isActive={isActive} />
                    <CustomizedAddIcon isActive={isActive} />
                </div>
            </ListItemIcon>
            <ListItemText primary={`Add Surf Spot`} />
        </CustomizedListItemButton>
    );
}
