import React, { useEffect, useState, useRef } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import SurfingIcon from "@mui/icons-material/Surfing";

import { Chart, Deposits, Orders, Title, Map, AppBar } from "./components";
import Context from "./context";
import LocContext from "./LocContext";
import { getUserLocation, getBuoys } from "../Utils";
import API from "./API";

// function Copyright(props) {
//     return (
//         <Typography
//             variant="body2"
//             color="text.secondary"
//             align="center"
//             {...props}
//         >
//             {"Copyright © "}
//             <Link color="inherit" href="https://mui.com/">
//                 Your Website
//             </Link>{" "}
//             {new Date().getFullYear()}
//             {"."}
//         </Typography>
//     );
// }

const mdTheme = createTheme();

function DashboardContent() {
    const [userLoc, setUserLoc] = useState();
    const [loc, setLoc] = useState();
    const [buoyMarkers, setBuoyMarkers] = useState({});
    const [surfSpots, setSurfSpots] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addSurfSpot, setAddSurfSpot] = useState(false);
    const [mapMode, setMapMode] = useState("swell");
    const [buoyIndex, setBuoyIndex] = useState(1);
    const [groupLocations, setGroupLocations] = useState(false);

    async function fetchSurfSpots(userLoc) {
        let surfSpots = await API.getSurfSpot(userLoc);
        setSurfSpots(surfSpots.spots);
    }

    async function getGroupLocations() {
        const groupLocs = await API.groupLocations();
        setGroupLocations(groupLocs);
    }

    useEffect(() => {
        getUserLocation(setUserLoc);
    }, []);

    useEffect(() => {
        setLoc(userLoc);
        //fetch surf spots
        fetchSurfSpots(userLoc);
    }, [userLoc]);

    useEffect(() => {
        if (!loc) return;
        getGroupLocations();
        getBuoys(
            loc.latitude,
            loc.longitude,
            buoyMarkers,
            setBuoyMarkers,
            setLoading
        );
    }, [loc]);

    useEffect(() => {
        if (!addSurfSpot) return;
        console.log("add surf spot");
        // set the zoom LOW
        // mapRef.current.setZoom(12);
        // mapRef.current.setCenter({
        //     lat: loc.latitude,
        //     lon: loc.longitude,
        // });
    }, [addSurfSpot]);

    useEffect(() => {
        console.log({ loading });
    }, [loading]);

    const addIcon = ({ lat, lng }, icon) => {
        if (!lat || !lng) return;

        // .setLngLat({ lat, lng })
        // .addTo(mapRef.current);

        // console.log(marker);
    };

    const STATE = {
        buoyIndex,
        setBuoyIndex,
        addSurfSpot,
        buoyMarkers,
        groupLocations,
        setGroupLocations,
        setAddSurfSpot,
        mapMode,
        setMapMode,
        userLoc,
        surfSpots,
        setSurfSpots,
    };

    return (
        <LocContext.Provider value={{ loc }}>
            <Context.Provider value={STATE}>
                <ThemeProvider theme={mdTheme}>
                    <Box sx={{ display: "flex" }}>
                        <CssBaseline />

                        <AppBar />
                        <Box
                            component="main"
                            sx={{
                                padding: 0,
                                backgroundColor: (theme) =>
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[900]
                                        : theme.palette.grey[900],
                                flexGrow: 1,
                                height: "100vh",
                                overflow: "auto",
                            }}
                        >
                            {/* <Container sx={{ pl: 0, pr: 0 }}> */}
                            <Grid container spacing={0}>
                                {/* Map */}
                                <Grid item xs={12}>
                                    <Paper
                                        sx={{
                                            p: 1,
                                            background: "#444",
                                            display: "flex",
                                            flexDirection: "column",
                                            height: 740,
                                        }}
                                    >
                                        <Map />
                                    </Paper>
                                </Grid>
                            </Grid>
                            {/* </Container> */}
                        </Box>
                    </Box>
                </ThemeProvider>
            </Context.Provider>
        </LocContext.Provider>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}
