import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import AirIcon from "@mui/icons-material/Air";
import ListItemText from "@mui/material/ListItemText";

import Context from "../../context";

const CustomizedListItemButton = styled(ListItemButton)`
    color: ${(props) => (props.isActive ? "green" : "")};

    :hover {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
    :active {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
`;

const CustomizedIcon = styled(AirIcon)`
    color: ${(props) => (props.isActive ? "green" : "")};

    :hover {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
    :active {
        color: ${(props) => (props.isActive ? "green" : "")};
    }
`;

export default function AddSurfReportButton() {
    const { mapMode, setMapMode } = useContext(Context);

    const isActive = mapMode === "wind";
    return (
        <CustomizedListItemButton
            button
            isActive={isActive}
            // divider
            // className={styles.isSetSurfReport}
            onClick={() => setMapMode("wind")}
        >
            <ListItemIcon>
                <CustomizedIcon isActive={isActive} />
            </ListItemIcon>
            <ListItemText primary={`Wind`} />
        </CustomizedListItemButton>
    );
}
