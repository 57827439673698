import React, { useState, useCallback, useRef } from "react";
import Geocode from "react-geocode";
import { makeStyles } from "@material-ui/styles";
import SurfingIcon from "@mui/icons-material/Surfing";
import { Marker } from "react-map-gl";
import NewSurfSpot from "../Dashboard/components/MapComponents/newSurfSpotInfo";
import Context from "../Dashboard/context";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API);
Geocode.setLocationType("APPROXIMATE");
// Enable or disable logs. Its optional.
Geocode.enableDebug();
const useStyles = makeStyles({
    iconStyle: {
        width: (props) => props.width,
        height: (props) => props.height,
    },
});

function DraggableSurfSpotMarker(props) {
    const { latitude, longitude } = props.map;
    const [address, setAddress] = useState(false);
    const fetchTimerRef = useRef(null);
    const [isFetching, setIsFetching] = useState(false);
    const [newSurfBreakLoc, setNewSurfBreakLoc] = useState({
        latitude,
        longitude,
    });

    const width = 100;
    const height = 100;
    const styles = useStyles({ width, height });
    // const StatusImage = props.statusImage;

    const onMarkerDragStart = useCallback((event) => {
		debugger

        // setNewSurfBreakLoc((_events) => ({
        //     ..._events,
        //     onDragStart: event.lngLat,
        // }));
        if (fetchTimerRef.current) {
            clearInterval(fetchTimerRef.current);
        }
    }, []);

    const onMarkerDrag = useCallback((event) => {
		debugger
        const { lat, lng } = event.lngLat

        // setNewSurfBreakLoc((_events) => ({ ..._events, onDrag: event.lngLat }));
        setNewSurfBreakLoc({
			longitude: lng,
            latitude: lat,
        });
    }, []);

    const onMarkerDragEnd = useCallback(async (event) => {
  
        const { lat, lng } = event.lngLat
        setNewSurfBreakLoc({
            longitude: lng,
            latitude: lat,
        });

        if (fetchTimerRef.current) {
            clearInterval(fetchTimerRef.current);
        }

        setIsFetching(true);

        const timer = setTimeout(async () => {
            try {
                // Get address from latitude & longitude.
                let place = await Geocode.fromLatLng(lat, lng);

                console.log(place);
                place = place.plus_code?.compound_code?.split(" ");
                place.shift();
                place = place.join(" ");
                fetchTimerRef.current = null;
                setAddress(place);
                setIsFetching(false);

            } catch (e) {

                console.log(e);
                fetchTimerRef.current = null;
                setAddress(false);
                setIsFetching(false);
            }
        }, 3000);

        fetchTimerRef.current = timer;
    }, []);

    return (
	/* Surfer Icon With Linear Gradient */
        <>
            <Marker
                latitude={newSurfBreakLoc.latitude}
                longitude={newSurfBreakLoc.longitude}
                offsetLeft={width * -0.5}
                offsetTop={height * -0.5}
                draggable
                onDragStart={onMarkerDragStart}
                onDrag={onMarkerDrag}
                onDragEnd={onMarkerDragEnd}
            >
                {/* Linear Gradient Gradient */}
                <svg width={0} height={0}>
                    <linearGradient
                        id="newSurfSpotLinearColors"
                        x1={1}
                        y1={0}
                        x2={1}
                        y2={1}
                    >
                        <stop offset={0} stopColor="rgba(21,184,74,1)" />
                        <stop offset={1} stopColor="rgba(207,113,8,1)" />
                    </linearGradient>
                </svg>
				{/* Surfer Icon */}
                <SurfingIcon
                    sx={{ fill: "url(#newSurfSpotLinearColors)" }}
                    className={styles.iconStyle}
                />
            </Marker>
            <NewSurfSpot
                loc={newSurfBreakLoc}
                address={address}
                fetchTimer={isFetching}
            />
        </>
    );
}

export default DraggableSurfSpotMarker;
