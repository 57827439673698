const baseUrl = process.env.REACT_APP_API_SERVER;
async function post(url, data) {
    try {
        let resp = await fetch(url, {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        resp = await resp.json();
        return resp;
    } catch (err) {
        return { err: err.message };
    }
}

async function get(url) {
    try {
        let resp = await fetch(url, {
            method: "get",
        });
        resp = await resp.json();
        return resp;
    } catch (err) {
        return { err: err.message };
    }
}

async function del(url) {
    try {
        let resp = await fetch(url, {
            method: "delete",
        });

        resp = await resp.json();
        return resp;
    } catch (err) {
        return { err: err.message };
    }
}

export default {
    addSurfReport,
    postNewSurfSpot,
    getSurfSpot,
    deleteSurfSpot,
    editSurfSpot,
    postSurfReport,
    groupLocations,
    getBuoy,
};

async function postSurfReport(data) {
    const path = "postSurfReport";
    return await post(`${baseUrl}/${path}`, data);
}

async function editSurfSpot(data) {
    const path = "editSurfSpot";
    return await post(`${baseUrl}/${path}`, data);
}

async function deleteSurfSpot(id) {
    const path = `deleteSurfSpot/${id}`;
    return await del(`${baseUrl}/${path}`);
}

async function addSurfReport() {
    const path = "addSurfReport";
    return await post(`${baseUrl}/${path}`);
}

async function postNewSurfSpot(data) {
    const path = "addSurfSpot";
    return await post(`${baseUrl}/${path}`, data);
}

async function getSurfSpot(userLoc) {
    if (!userLoc) return [];
    const { latitude, longitude } = userLoc;
    const path = "getSurfSpots";
    return await get(`${baseUrl}/${path}/${latitude}/${longitude}`);
}

async function groupLocations() {
    return await get(`${baseUrl}/groupLocations`);
}

async function getBuoy(stationId) {
    return await get(`${baseUrl}/wavedata/stationId/${stationId}`);
}
