import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import PlaceIcon from "@mui/icons-material/Place";
import { Marker } from "react-map-gl";

const useStyles = makeStyles({
    iconStyle: {
        minWidth: (props) => props.width,
        minHeight: (props) => props.height,
    },
});

function SurferMarker(props) {
    const styles = useStyles(props);
    // const StatusImage = props.statusImage;
    return (
        <Marker
            latitude={props.latitude}
            longitude={props.longitude}
            offsetLeft={props.width * -0.5}
            offsetTop={props.height * -0.5}
        >
            {/* <Surfer /> */}

            <svg width={0} height={0}>
                <linearGradient
                    id="placeLinearColors"
                    x1={1}
                    y1={0}
                    x2={1}
                    y2={1}
                >
                    <stop offset={0} stopColor="rgba(21,184,74,1)" />
                    <stop offset={1} stopColor="rgba(207,113,8,1)" />
                </linearGradient>
            </svg>
            <PlaceIcon sx={{ fill: "tomato" }} className={styles.iconStyle} />
        </Marker>
    );
}

export default SurferMarker;
