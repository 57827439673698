import React from "react";
import { Popup } from "react-map-gl";
import BuoyInfo from "./BuoyInfo";

export default function BuoyInfoPopup(props) {
    const { info, setInfo } = props;

    console.log(info);

    const [longitude, latitude] = info[0]?.coords?.coordinates;
    return (
        <Popup
            offsetTop={20}
            tipSize={5}
            anchor="top"
            longitude={longitude}
            latitude={latitude}
            closeOnClick={false}
            onClose={() => setInfo(false)}
            maxWidth={"100%"}
        >
            <BuoyInfo info={info} />
        </Popup>
    );
}
