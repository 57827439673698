import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
    return `${value}°C`;
}

export default function BuoyIndexSlider({ filledMarkers = {} }) {
    filledMarkers = Object.keys(filledMarkers).reduce((acc, buoyId) => {
        acc.push(filledMarkers[buoyId]);
        return acc;
    }, []);
    if (filledMarkers?.length === 0) return <></>;

    const buoyDataLength = filledMarkers[0].length;

    return (
        <Box sx={{ width: 300, position: "absolute", left: "40%", bottom: 0 }}>
            <Slider
                aria-label="Time"
                defaultValue={30}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                valueLabelFormat={(a) => a * 2}
                step={1}
                marks
                min={0}
                max={buoyDataLength}
            />
        </Box>
    );
}
