import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import SurfingIcon from "@mui/icons-material/Surfing";
import { Marker } from "react-map-gl";

const useStyles = makeStyles({
    iconStyle: {
        minWidth: (props) => props.width,
        minHeight: (props) => props.height,
    },
});

function SurferMarker(props) {
    const { surfSpot, onClick } = props;
    let [longitude, latitude] = surfSpot.coords.coordinates;

    const width = 48;
    const height = 48;
    const styles = useStyles({ width, height });

    return (
        <Marker
            latitude={latitude}
            longitude={longitude}
            offsetLeft={width * -0.5}
            offsetTop={height * -0.5}
            onClick={() => onClick(surfSpot)}
        >
            {/* <Surfer /> */}

            <svg width={0} height={0}>
                <linearGradient
                    id="surfingLinearColors"
                    x1={1}
                    y1={0}
                    x2={1}
                    y2={1}
                >
                    <stop offset={0.4} stopColor="#C2B280" />
                    {/* <stop offset={0.4} stopColor="#ff2800" /> */}
                    <stop offset={0.75} stopColor="#ffdc64" />
                    <stop offset={1} stopColor="#d4f1f9" />
                </linearGradient>
            </svg>
            <SurfingIcon
                sx={{ fill: "url(#surfingLinearColors)" }}
                className={styles.iconStyle}
            />
        </Marker>
    );
}

export default SurferMarker;
