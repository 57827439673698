import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BuoyIcon from "@mui/icons-material/Navigation";
import { Marker } from "react-map-gl";
import {
    sizePeriod,
    cardinalToDegrees,
    colorFt,
    colorWspd,
    parseDegrees,
    sizeGust,
    colorTemp,
} from "../Utils/Convert";
let count = 0;

const useStyles = makeStyles({
    iconStyle: {
        minWidth: (props) => props.width,
        minHeight: (props) => props.height,
    },
});

function BuoyMarker(props) {
    const { buoyData, onClick, mapMode } = props;
    // console.log({ buoyData });
    const buoyIndex = props.buoyIndex;

    const buoy = buoyData.slice(-buoyIndex)[0];
    const prevBuoy = buoyData.slice(-(buoyIndex + 1))[0];
    const [longitude, latitude] = buoy?.coords?.coordinates;

    let width;
    let height;
    let angle = 180;
    let value;
    let color1, color2;
    if (mapMode === "swell") {
        const size = sizePeriod(buoy.period);
        width = height = size;
        angle = cardinalToDegrees(buoy.swellDir);
        color1 = colorFt(buoy.height);
        color2 = colorFt(prevBuoy.height);
        value = `${buoy.period}sec ${buoy.height}ft`;
    }

    if (mapMode === "wind") {
        const size = sizeGust(buoy.windGust);
        width = height = size;
        angle = cardinalToDegrees(buoy.windDir);
        color1 = colorWspd(buoy.windSpeed);
        color2 = colorWspd(buoy.windGust);
        value = `${buoy.windSpeed} `;
    }

    if (mapMode === "temperature") {
        const size = 20; //sizeGust(buoy.windGust);
        width = height = size;
        angle = 90; //cardinalToDegrees(buoy.windDir);
        color1 = colorTemp(buoy.airTemp);
        color2 = colorTemp(buoy.waterTemp);
        value = `${buoy.airTemp || buoy.waterTemp} `;
    }

    const styles = useStyles({ width, height });

    let date = new Date(buoy.GMT).toLocaleString();
    // console.log(date);
    const MemoMarker = useMemo(() => {
        return (
            <Marker
                latitude={latitude}
                longitude={longitude}
                offsetLeft={width * -0.5}
                offsetTop={height * -0.5}
                onClick={() => onClick(true)}
            >
                {/* <Surfer /> */}
                <BuoyIcon
                    sx={{
                        fill: `url(#buoyLinearColors${buoy.stationId})`,
                        transform: `rotate(${angle}deg)`,
                    }}
                    className={styles.iconStyle}
                />
                <svg width={0} height={0}>
                    <linearGradient
                        id={`buoyLinearColors${buoy.stationId}`}
                        x1={0}
                        y1={0}
                        x2={1}
                        y2={1}
                    >
                        <stop offset={0} stopColor={color1} />
                        <stop offset={1} stopColor={color2} />
                    </linearGradient>
                </svg>
            </Marker>
        );
    }, [latitude, longitude, angle, width]);

    return MemoMarker;
}

export default BuoyMarker;
