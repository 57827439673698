export default [
    "cornflowerblue",
    "dodgerblue",
    "steelblue",
    "royalblue",
    "forestgreen",
    "lightgreen",
    "greenyellow",
    "yellow",
    "darkorange",
    "orange",
    "orangered",
    "red",
    "mediumvioletred",
    "palevioletred",
    "pink",
];
